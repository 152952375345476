<template>
  <!-- 主要内容框架 -->
  <div class="MainContent">
    <!-- 卡片区域 -->
    <div class="cards_">
      <slot name="cards"></slot>
    </div>
    <!-- 图表区域 -->
    <div class="chart">
      <slot name="chart_year"></slot>
      <slot name="chart_month"></slot>
      <slot name="chart_day"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContent",
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.MainContent {
  width: 100%;
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 15px;
  // background-color: #f1f7fe;
}
.cards_ {
  width: 100%;
}
.chart {
  // margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  flex-direction: column;
  
  // div {
  //   margin: 0 auto;
  //   width: 100%;
  //   height: 350px;
  // }
}
</style>