<template>
  <div class="section">
    <div class="sectionTop">
      <div class="title">{{title}}</div>
      <div class="infos">
        <slot name="feature"></slot>
      </div>
    </div>
    <div class="sectionContent">
      <slot name="chart"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonCard',
  props: ['title']
}
</script>

<style scoped lang="scss">
.section {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 30px;
  margin-top: 20px;
  .sectionTop {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .infos{
      display: flex;
      justify-content: space-between;
      align-items: center;
      div{
        display: flex;
      }
    }
    .title {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>