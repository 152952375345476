<template>
  <div class="month-all">
    <!-- 月份切换 -->
    <div class="month-change">
      <!-- <div class="infos"></div> -->
    </div>
    <div id="chart-month" ref="chart_month" v-show="!dataShow"></div>
    <div class="noData" v-show="dataShow">暂无数据</div>
  </div>
</template>

<script>

import  {mapState} from 'vuex'
import {getMonths} from '@common/publicmethods.js'
export default {
  name: "ChartMonth",
  props:{
    monthData:{
      type:Array,
      default(){
        return []
      }
    }
  },
  data() {
    return {
      monthNow: "", // 选择的月份
      month: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",], // 保存数据月份
      datas: [], // 保存数据
      // year: null,
      pickerOptionsone: {
        // disabledDate: (time) => {
        //   //let arr = this.year;
        //   let arr = ["1","2","3","4","5","6","7","8","9","10","11","12",];
        //   var lj = true;
        //   arr.forEach((i) => {
        //     lj =
        //       lj &&
        //       (time.getTime() < new Date(this.year, i, 0) ||
        //         time.getTime() > new Date(this.year, i, 0));
        //   });
        //   return lj;
        // },
      },
      dataShow:false
    };
  },
  components: {},
  mounted() {
   
    this.createChart();

    this.$nextTick(()=>{
      this.echartsSize()
    })

  },
  computed: {
  
    ...mapState({
      viewWin: state => state.viewWin
    })
  },
  watch: {
   
    viewWin:{
      deep:true,
      handler:function(){
        this.echartsSize()
      }
    },
     //处理月份数据
    monthData:{
      deep:true,
      handler:function(){
        this.datas = this.monthData

        //对传进来的空数据进行判断
        this.dataShow = this.monthData.length == 0 ? true : false
        this.createChart()
        
      }
    }

  },
  methods: {
  
    // 生成图表
    createChart() {
      let month = this.$echarts.init(this.$refs.chart_month);
      //console.log(this.$refs.chart_month);
      const options = {
        title: {},
        grid: {
          // top: "10",
          // left: "10%",
          width:'auto'
        },
        tooltip: {},
        xAxis: {
          data:this.month,
          axisTick: {
            // x轴刻度线
            show: false,
          },
          axisLine: {
            // 显示轴线
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            // y轴刻度线
            show: false,
          },
          axisLine: {
            // 显示轴线
            show: false,
          },
          splitLine:{
            lineStyle:{
              type:'dashed'
            },
          },
          axisLabel:{
            color:'#95a4b5',
            fontSize:'13px'
          }

        },
        // dataZoom: [
        //   {type: 'inside'}, 
        //   {type: 'slider'}
        // ],
        series: [
          {
            name: "订单总数",
            type: "line",
            smooth:true,
            data: this.datas,
            barWidth: "16",
            // showSymbol: false,
            itemStyle: {
              // borderRadius: [10, 10, 0, 0],
              borderWidth:1,
              normal:{
              lineStyle:{
                color:{
                    type:'linear',
                    x: 0.2,
                    y: 0.2,
                    x2: 1,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#68f3fe' 
                    },{
                      offset: 1, color: '#48c7f7' 
                    },{
                      offset: 1, color: '#48c7f7' 
                    },{
                      offset: 1, color: '#657cf1' 
                    },{
                      offset: 1, color: '#7a64f2' 
                    }],
                  }
                }
              }
            },
            label: {
              show: true,
              position: "top",
              valueAnimation: true,
            },
            areaStyle: {
              color:{
                type:'linear',
                x: 0.2,
                y: 0.2,
                x2: 1,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#d1fcff' 
                },{
                  offset: 1, color: '#d6d0fb' 
                },{
                  offset: 1, color: '#c0e1fb' 
                },{
                  offset: 1, color: '#c7d6fa' 
                },{
                  offset: 1, color: '#d7d0fb' 
                }],
              }
            },
           
          },
        ],
      };
      month.setOption(options);

    },

    echartsSize() {
      let mychartDom = this.$refs.chart_month; 
      let mychart =  this.$echarts.getInstanceByDom(mychartDom); 
      if (mychart) { mychart.resize(); }
    },

   
   
  },
};
</script>

<style lang='scss' scoped>
#chart-month {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 60px;
  margin-top: 20px;
}
.month-all {
  width: 100%;
  position: relative;
  // margin: 0 auto;
  background-color: #fff;
  border-radius: 15px;
  height: 340px;
  margin-top: 20px;
}

.noData{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #888;
  }


.month-change{
  width: 100%;
  // position: absolute;
  padding: 20px 0px 0px 60px;
  display: flex;
  justify-content: space-between;
  .infos{
    width: 210px;
    display: flex;
    margin-right: 40px;
    .share{
      width: 70px;
      border: 1px solid #eee;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-radius: 6px;
      margin-left: 10px;
      cursor: pointer;
      img{
        width: 15px;
        height: 15px;
        margin-left: 4px;
      }
    }
  }
}

// /deep/.el-date-editor.el-input,
// .el-date-editor.el-input__inner {
//   width: 120px;
// }



/deep/ .el-input__inner {
  transform: scaleY(0.9);
}

// .el-icon-date:before{
//   top:0
// }
.title {
  font-size: 16px;
  font-weight: bold;

}
</style>