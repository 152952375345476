<template>
  <div class="day-all">
    <!-- 天切换 -->
    <div class="day-change"></div>
    <div id="chart-day" ref="chart_day" v-resize="echartsSize" v-show="!dataShow"></div>
    <div class="noData" v-show="dataShow">暂无数据</div>
  </div>
</template>

<script>
import  {mapState} from 'vuex'
import {mGetDate} from '@common/publicmethods.js'

export default {
  name: 'ChartDay',
  props:{
    dayData:{
      type:Array,
      default(){
        return []
      }
    },
    days:{
      type:Array
    }
  },
  data() { 
    return {
      dayNow: "",
      datas:[],
      day:[],
      pickerOptionsone: {
        // disabledDate: (time) => {
        //   //let arr = this.year;
        //   let arr = [];
        //   for(let i = 1;i<=31;i++){
        //     arr.push(`${i}`)
        //   }
        //   var lj = true;
        //   arr.forEach((i) => {
        //     lj =
        //       lj &&
        //       (time.getTime() < new Date(this.year, this.month, i) ||
        //         time.getTime() > new Date(this.year, this.month, i));
        //   });
        //   return lj;
        // },
      },
      charts:null,
      dataShow:false,
      
    }
  },
  components:{
  },
  mounted() {
    this.day = this.createDay(mGetDate())
    // this.dayNow = `${this.month}-01`;
    this.createChart();
    this.$nextTick(()=>{
      this.echartsSize()
    })
  },
  computed: {
    ...mapState({
      viewWin: state => state.viewWin
    })
  },
  watch: {
   
 
    viewWin:{
      deep:true,
      handler:function(){
        this.echartsSize()
      }
    },
    dayData:{
      deep:true,
      handler:function(){
        this.datas = this.dayData;
        
        this.dataShow = this.dayData.length == 0 ? true : false
        this.createChart()
      }
    },
    days:{
      deep:true,
      handler:function(){
        this.day = this.createDay(this.days.length)
      }
    }

  },
  methods:{
  
    // 生成图表
    createChart() {
      this.charts = this.$echarts.init(this.$refs.chart_day);
      let day = this.$echarts.init(this.$refs.chart_day);
      const options = {
        title: {},
        grid: {
          // top: "10",
          // left: "10%",
          width:'auto'
        },
        tooltip: {},
        xAxis: {
          data: this.day,
          axisTick: {
            // x轴刻度线
            show: false,
          },
          axisLine: {
            // 显示轴线
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            // y轴刻度线
            show: false,
          },
          axisLine: {
            // 显示轴线
            show: false,
          },
          splitLine:{
            lineStyle:{
              type:'dashed'
            },
          },
          // max:100,
          // splitNumber:2
        },
        dataZoom: [
          {type: 'inside'}, 
          {type: 'slider'}
        ],
        series: [
          {
            name: "订单总数",
            type: "line",
            data: this.datas,
            // smooth: true,
            lineStyle:{
              color: "rgb(86, 222, 255)",
            },
            itemStyle:{
              // color: "#2f42f2",
              borderColor: "#2f42f2",
              borderWidth: "0"
            },
            areaStyle:{
              color: {
                type: "linear",
                x: 0.2,
                y: 0.2,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#ccdcfe", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#c0c9fb", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            label: {
              show: true,
              position: "top",
              valueAnimation: true,
            },
          },
        ],
      };
      day.setOption(options);
      
      
    },

    echartsSize() {
      let mychartDom = this.$refs.chart_day; 
      let mychart =  this.$echarts.getInstanceByDom(mychartDom); 
      if (mychart) { mychart.resize(); }
    },
   

    createData(){
      let arr = [];
      for(let i=0;i<31;i++){
        if(i == 4 || i == 11 || i == 15){
          arr.push((i+Math.floor(Math.random()*5))*200)
        }else{
          arr.push(0)
        }
        
      }
      return arr
    },
    createDay(item){
      let arr = [];
      for(let i = 1;i<=item;i++){
        if(i<10){
          arr.push(`0${i}日`)
        }else{
          arr.push(`${i}日`)
        }
        
      }
      return arr
    }
  },
 }
</script>

<style lang='scss' scoped>
// #chart-day {
//   width: 400px;
//   height: 300px;
// }
.day-all {
  width: 100%;
  position: relative;
  // margin: 0 auto;
  background-color: #fff;
  border-radius: 15px;
  height: 550px;
  margin-top: 20px;
}
#chart-day {
  margin-top: 50px;
  width: 100%;
  height: 450px;
  position: absolute;
  top: 20px;
  // position: absolute;
  // left: 50%;
  // transform: translate(-50%,0);
}

/deep/.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 130px;
}
/deep/ .el-input__inner {
  transform: scaleY(0.9);
}


.day-change{
  width: 100%;
  // position: absolute;
  padding: 20px 0px 0px 60px;
  display: flex;
  justify-content: space-between;
  .infos{
    // width: 210px;
    display: flex;
    margin-right: 40px;
    .share{
      width: 70px;
      border: 1px solid #eee;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-radius: 6px;
      margin-left: 10px;
      cursor: pointer;
      img{
        width: 15px;
        height: 15px;
        margin-left: 4px;
      }
    }
  }
}

.title {
  font-size: 16px;
  font-weight: bold;

}

.noData{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #888;
  }
</style>