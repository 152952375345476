<template>
  <!-- 卡片组件 -->
  <div class="Card" >
    <div
      class="card"
      :style="{
        background:
          'linear-gradient(30deg, ' + colorBegin + ', ' + colorEnd + ')',
      }"
    
    >
      <div class="content">
        <div class="cdata">
          <img :src="imgs" alt="" class="icons" />
        </div>
        <div class="infos">
          <div class="title">{{ title }}</div>
          <div class="data">
            <count-to
              :startVal="0"
              :endVal="!totalData ? 0 : totalData"
              :duration="1000"
              :decimals='decimals'
            ></count-to>
          </div>
        </div>
      </div>
      <img :src="img" alt="" class="icon" />
    </div>
    <div
      class="shadow"
      :style="{ 'box-shadow': '0 0 20px 4px ' + colorBegin }"
    ></div>
  </div>
</template>

<script>
//count to 插件
import CountTo from "vue-count-to";

export default {
  name: "Card",
  props: [
    "title",
    "totalData",
    "colorBegin",
    "colorEnd",
    "icon",
    "icons",
    "decimals"
  ],
  data() {
    return {
      img: require("@assets/images/" + this.icon + ".png"),
      imgs:require("@assets/images/" + this.icons + ".png")
    };
  },
  // mixins: [mobile],
  components: {
    CountTo,
  },
  mounted() {},
  methods: {
    
  },
};
</script>

<style lang='scss' scoped>
.Card {
  // min-width: 400px;
  // max-width: 480px;
  width: 47%;
  height: 180px;
  position: relative;
}
.card {
  border-radius: 30px;
  overflow: hidden;
  padding: 26px 42px;
  position: relative;
  color: #fff;
  transform: translateZ(50px);
  position: relative;
  z-index: 9;

  .content {
    position: relative;
    z-index: 4;
    height: 90px;
    .title {
      font-size: 20px;
    }
    .data {
      // margin-top: 12px;
      font-size: 40px;
      // font-family: pfd;
      font-weight: bold;
    }
    .cdata {
      position: absolute;
      top: 15px;
      width: 60px;
      height: 60px;
      background-color: #fff;
      border-radius: 15px;
      text-align: center;
      line-height: 76px;
    }

    .infos{
      position: absolute;
      left: 20%;
      top: 12%;
    }
  }
  @keyframes bg {
    0% {
      text-shadow: 0 0 0 2px red;
    }
    100% {
      text-shadow: 0 0 10px 10px red;
    }
  }
  // 图片icon
  .icon {
    width: 130px;
    height: 138px;
    position: absolute;
    top: 15px;
    right: 70px;
    bottom: 0;
    z-index: 3;
  }
}
.shadow {
  position: absolute;
  background: none;
  width: 92%;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  // box-shadow: 0 0 20px 4px #000;
  z-index: 3;
}
.msg {
  &::after {
    content: "";
    position: absolute;
    top: -6px;

    right: -8px;
    width: 8px;
    height: 8px;
    border: 4px solid red;
    border-radius: 50%;
    background: red;
  }
}


</style>