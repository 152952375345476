<template>
  <div class="year-all">
    <!-- 年份切换 -->
    <div class="year-change">
      <!-- <span class="title">按年统计</span> -->
      <div class="infos">
        <!-- <el-date-picker v-model="currentYear" @change="yearChange" type="year" placeholder="选择年" value-format="yyyy"
          :picker-options="pickerOptionsone" size="mini" :clearable="false">
        </el-date-picker> -->
        
      </div>
    </div>
    <div id="chart-year" ref="chart_year" v-show="!dataShow"></div>
    <div class="noData" v-show="dataShow">暂无数据</div>
  </div>
</template>

<script>
import  {mapState} from 'vuex';

// import elementResize from 'element-resize-detector';

export default {
  name: "ChartYear",
  props: {
    yearData: {
      type: Array,
      dafault() {
        return [];
      },
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear() + '',
      yearNow: [], // 选择的年份
      saveYear: [], // 保存数据年份
      datas: [], // 保存数据
      // 限制年份
      pickerOptionsone: {
        // disabledDate: (time) => {
        //   let arr = this.saveYear;
        //   //let arr = [2010, 2012, 2015, 2016, 2018];
        //   var lj = true;
        //   arr.forEach((i) => {
        //     lj =
        //       lj &&
        //       (time.getTime() < new Date(i, 1, 0) ||
        //         time.getTime() > new Date(i, 12, 0));
        //   });
        //   return lj;
        // },
      },
      dataShow:false
    };
  },
  components: {},

  computed:{
    ...mapState({
      viewWin: state => state.viewWin
    })

  },
  mounted() {
    
    this.createChart(); // 生成图表
    // this.yearDeal(this.yearData); // 处理传入的数据年份
    
    this.$nextTick(()=>{
      this.echartsSize()
    })

   
  },

  watch:{
    viewWin:{
      deep:true,
      handler:function(){
        this.echartsSize()
      }
    },
    yearData:{
      deep:true,
      handler:function(){
        this.datas = []
        this.yearNow = []
        this.yearData.forEach(item=>{
          // console.log(item.year,this.currentYear)
            this.datas.push(item.sumNums)  //y轴数据
            this.yearNow.push(item.year) // x轴年份
        })

        //对传进来的空数据进行判断
        this.dataShow = this.yearData.length == 0 ? true : false

        this.createChart()
      }
    }
  },


  methods: {
    // 选择年份
    // yearChange(e) {
    //   console.log("选择了年份：", e);
    //   // 保存年份
    //   // this.$store.commit("setCurrentYear", e + "")
    //   this.$emit('yearChange',e)
    //   // this.yearData.forEach((item) => {
    //   //   if (item.year == e) {
    //   //     this.datas = [item.sumNums];
    //   //     this.yearNow = [item.year]
    //   //   }
    //   // });
    //   this.createChart();
    // },

    echartsSize() {
      let mychartDom = this.$refs.chart_year; 
      let mychart =  this.$echarts.getInstanceByDom(mychartDom); 
      if (mychart) { mychart.resize(); }
    },

    // 处理传进的年份，
    // yearDeal(e) {
     
    //   let years = [];
    //   e.forEach((item) => {
    //     years.push(item.year + "");
    //   });
    //   this.saveYear = years;
      
    // },

    // 生成图表
    createChart() {
      let yearChart = this.$echarts.init(this.$refs.chart_year);
      
      //console.log(this.$refs.chart_year);
      const options = {
        title: {},
        grid: {
          // right:'0',
          width:'auto'
        },
        tooltip: {},
        xAxis: {
          data: this.yearNow,
          axisTick: {
            // x轴刻度线
            show: false,
          },
          axisLine: {
            // 显示轴线
            show: false,
          },
          axisLabel: {
            color: '#95a4b5',
            fontSize: '13px'
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            // y轴刻度线
            show: false,
          },
          axisLine: {
            // 显示轴线
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            },
          },
          axisLabel: {
            color: '#95a4b5',
            fontSize: '13px'
          }
        },
        series: [
          {
            name: "订单总数",
            type: "bar",
            fontSize: '13px',
            data: this.datas,
            barWidth: "16",
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 1, color: 'rgb(221,236,254)' // 0% 处的颜色
                }, {
                  offset: 0, color: 'rgb(50,131,227)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              },
              borderRadius: [10, 10, 0, 0],
            },
            label: {
              show: true,
              position: "top",
              valueAnimation: true,
              fontSize: '13px',
              fontWeight: 'bold'
            },
          },
        ],
      };
      yearChart.setOption(options);
     
    },
  },
  
};
</script>

<style lang='scss' scoped>
.year-all {
  width: 100%;
  position: relative;
  // margin: 0 auto;
  background-color: #fff;
  border-radius: 15px;
  height: 340px;
  
}

.noData{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #888;
  }

#chart-year {
  width: 100%;
  height: 320px;
  position: absolute;
  top: 60px;
}

.year-change {
  width: 100%;
  // position: absolute;
  padding: 20px 0px 0px 60px;
  display: flex;
  justify-content: space-between;

  .infos {
    width: 210px;
    display: flex;

    // margin-right: 20px;
    .share {
      width: 70px;
      border: 1px solid #eee;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-radius: 6px;
      margin-left: 10px;
      cursor: pointer;

      img {
        width: 15px;
        height: 15px;
        margin-left: 4px;
      }
    }
  }
}

/deep/.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100px;
}

/deep/ .el-input__inner {
  transform: scaleY(0.9);
}


.title {
  font-size: 16px;
  font-weight: bold;

}
</style>