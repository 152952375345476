<template>
  <div class="StatisticAlanalysis">
    <el-tabs @tab-click="handleClick" v-loading="loading" element-loading-text="拼命加载中">
      <el-tab-pane v-for="item in tabitem" :key="item.name" :label="item.label">
        <!-- 图表组件 -->
        <main-content class="main">
          <!-- 数据总数卡片 -->
          <div slot="cards">
            <div class="cards">
              <card :colorBegin="'#ffcc33'" :colorEnd="'#ff622f'" :title="'订单总数'" :totalData="orderTotal"
                :icon="'orderLeft'" :icons="'orderSmall'" :decimals="0"></card>

              <card :colorBegin="'#50da6b'" :colorEnd="'#43c89d'" :title="'订单金额'" :totalData="moneyTotal"
                :icon="'moneyRight'" :icons="'moneySmall'" :decimals="2"></card>
            </div>
          </div>
          <!-- 数据图表 -->

          <div slot="chart_year">
            <CommonCard title="按年统计" v-loading="echartLoading">
              <div slot="feature">
                <div class="share" @click="yeartExport(yearData)">
                  <img src="@assets/images/share.png" alt="">
                  <span>导出</span>
                </div>
              </div>
              <chart-year slot="chart" :year-data="yearData" :ref="'cheartYear' + item.id">
              </chart-year>
            </CommonCard>

          </div>

          <div slot="chart_month">
            <CommonCard title="按月统计" v-loading="monthsLoading">
              <div slot="feature">
                <el-date-picker v-model="monthNow" type="year" value-format="yyyy" @change="monthChange" size="mini"
                  :clearable="false" placeholder="选择年份">
                </el-date-picker>
                <div class="share">
                  <img src="@assets/images/share.png" alt="">
                  <span @click="monthsExport(saveExcelMonth)">导出</span>
                </div>
              </div>
              <chart-month slot="chart" :month-data="monthData" :ref="'cheartMonth' + item.id"></chart-month>
            </CommonCard>
          </div>
          <div slot="chart_day">
            <CommonCard title="按日统计" v-loading="dayLoading">
              <div slot="feature">
                <el-date-picker v-model="dayNow" type="month" placeholder="选择月" value-format="yyyy-MM" size="mini"
                  :clearable="false" @change="dayChange" value="yyyy-MM">
                </el-date-picker>
                <div class="share">
                  <img src="@assets/images/share.png" alt="">
                  <span @click="dayExport(saveExcelDay)">导出</span>
                </div>
              </div>
              <chart-day slot="chart" :days="days" :day-data="dayData" :ref="'cheartDay' + item.id"></chart-day>
            </CommonCard>


          </div>
        </main-content>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// 引入子组件
import MainContent from "./childcomponent/MainContent";
import ChartYear from "./childcomponent/ChartYear";
import ChartMonth from "./childcomponent/ChartMonth";
import ChartDay from "./childcomponent/ChartDay";
import Card from './childcomponent/Card.vue'
import CommonCard from '@components/content/commonCard/CommonCard'
import { mGetDate } from '@common/publicmethods.js'
import { getMonths, getMonthDay, getCountDays, doHandleMonth,openDownloadDialog,sheet2blob } from "@common/publicmethods";



// import * as XLSX from 'xlsx/xlsx.mjs';
import { xlsx }  from '../../common/xlsx'

export default {
  name: "StatisticAlanalysis",
  data() {
    return {
      tabitem: [
        {
          name: "门票统计",
          label: "门票统计",
          closable: false,
          id: 0
        },
        {
          name: "商品统计",
          label: "商品统计",
          closable: false,
          id: 1
        },
      ],
      dataAll: {}, // 总的数据
      loading: false,
      yearData: [], //年份数据
      monthData: [], //月份数据
      dayData: [], //天份数据
      orderTotal: 0,
      moneyTotal: 0,
      monthNow: new Date().getFullYear() + "",
      dayNow: doHandleMonth() + "",
      month: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      days: [],
      echartLoading: false,
      monthsLoading:false,
      dayLoading:false,
      listHanderYear:{
        year:'日期',
        sumNums:'总数'
        
      },
      listHanderMonths:{
        months:'日期',
        sumNums:'总数'
        
      },
      listHanderDays:{
        statistical_date:'日期',
        number:'总数'
        
      },
      saveExcelMonth:[],
      saveExcelDay:[],
      currentYear:new Date().getFullYear() + "",
      recordTabIndex:0
    };
  },
  components: {
    MainContent,
    ChartYear,
    ChartMonth,
    ChartDay,
    Card,
    CommonCard
  },
  mounted() {
    this.queryTotal() //查询总数
    this.queryYearData() //查询年份数据
    this.queryMonthData() //查询月份数据
    this.queryDayData() //查询天数据
    this.handleDay()
  },

  methods: {
    //切换tab改变图表样式
    handleClick(tab, event) {
      this.$nextTick(() => {
        this.$refs[`cheartYear${tab.index}`][0].echartsSize()
        this.$refs[`cheartMonth${tab.index}`][0].echartsSize()
        this.$refs[`cheartDay${tab.index}`][0].echartsSize()
      })
      this.recordTabIndex = tab.index

      if(tab.index == 0){ //门票统计
        this.queryTotal(0)
        this.queryYearData({type:0,name:'总数'})
        this.queryMonthData({type:0,name:'总数',start_d:`${this.currentYear}-01-01`,end_d:`${this.currentYear}-12-31`})
        this.queryDayData({type:0})
      }else if(tab.index == 1){ //商品统计
        this.queryTotal(2)
        this.queryYearData({type:2,name:'总数'})
        this.queryMonthData({type:2,name:'总数',start_d:`${this.currentYear}-01-01`,end_d:`${this.currentYear}-12-31`})
        this.queryDayData({type:2})
      }
     
    },

    //公共请求函数
    queryDataByResourceField(params, resourceField) {
      return new Promise((resolve, reject) => {
        this.$Request({
          method: "get",
          url: this.$Api[resourceField],
          params
        })
          .then((result) => {
            resolve(result)
          })
          .catch((err) => {
            reject(err)
          });
      })


    },

    //查询总数
    queryTotal(type=0) {
      let params = {
        directory_code: 'order',
        data:{type}
      }
      let params1 = {
        directory_code: 'order',
        type:"sum",
        sumfield:"price",
        data:{type}
      }
      // 总数
      this.queryDataByResourceField(params, 'activeStatisticsdata').then(res => {
        this.orderTotal = res.data?.list[0]?.countNums
      })
      // 金额
      this.queryDataByResourceField(params1, 'activeStatisticsdata').then(res => {
        this.moneyTotal = res.data?.list[0]?.sumNums
      })
    },

    //查询年份
    queryYearData({type,name} ={type:0,name:'总数'}) {
      this.echartLoading = true
      let data = {
        type,
        name
      }
      let params = {
        directory_code: 'order',
        type: 'year',
        data: data
      }
      this.queryDataByResourceField(params, 'completeStatisticsdata').then(res => {
        this.yearData = res.data.list;
        this.echartLoading = false
      }).catch(()=>{
        this.echartLoading = false
      })
    },
     //处理月份
     monthChange(e) {
      let year_start = `${e}-01-01`;
      let year_end = `${e}-12-31`

      this.currentYear = e;
      //查询年份数据
      if(this.recordTabIndex == 0){
        this.queryMonthData({type:0,start_d:year_start, end_d:year_end})
      }else{
        this.queryMonthData({type:2,start_d:year_start, end_d:year_end})
      }

      //选择年份自动查询选择年份的第一个月数据
      // if(this.recordTabIndex == 0){
      //   this.queryDayData({type:0})
      // }else{
      //   this.queryDayData({type:2})
      // }
      // this.dayNow = `${e}-01`
    },


    //查询年份
    queryMonthData({type = 0,name = '总数',start_d = `${new Date().getFullYear()}-01-01`, end_d = `${new Date().getFullYear()}-12-31`} ={}) {
      
      this.monthsLoading = true
      let data = {
        type,
        name,
        date_start: start_d,
        date_end: end_d,
      }

      let params = {
        directory_code: 'order',
        type: 'month',
        data: data
      }
      this.queryDataByResourceField(params, 'completeStatisticsdata').then(res => {
        let index = 0
        let month = res.data.list
        this.monthsLoading = false
        this.saveExcelMonth = res.data.list
        this.monthData = []
        this.month.forEach((item) => {
          if (item != month[index]?.months?.slice(4)) {
            this.monthData.push(0)
          } else {
            this.monthData.push(month[index].sumNums)
            index++
          }
        })
      }).catch(()=>{
        this.monthsLoading = false
      })
    },

    //查询日
    queryDayData({type=0,name="总数"} = {}) {
      this.dayLoading = true;
      let data = {
        type,
        name,
        date_start: `${this.dayNow}-01`,
        date_end:  `${this.dayNow}-${getCountDays(this.dayNow.slice(5))}`,
        date_filed:"create_time",
        order_by:"create_time asc",
      }
      
      let params = {
        directory_code: 'orderstatistics',
        data: data,
      }
      this.queryDataByResourceField(params, 'queryDataOfResource').then(res => {
        let index = 0
        let day = res.data;
        this.saveExcelDay = res.data;
        this.dayLoading = false;

        this.dayData = []

        this.days.forEach((item) => {
          if (item != day[index]?.statistical_date?.slice(8)) {
            this.dayData.push(0)
          } else {
            this.dayData.push(day[index].number)
            index++
          }
        })

      })
    },

    //处理每月中多少天
    handleDay() {
      console.log(mGetDate())
      for (let i = 1; i <= mGetDate(); i++) {
        if (i < 10) {
          this.days.push(`0${i}`)
        } else {
          this.days.push(`${i}`)
        }

      }
    },

    //处理选中的天数
    dayChange(e) {

      if(this.recordTabIndex == 0){
        this.queryDayData({type:0})
      }else{
        this.queryDayData({type:2})
      }
      this.days = []
      console.log(e.slice(5))
      for (let i = 1; i <= getCountDays(e.slice(5)); i++) {
        if (i < 10) {
          this.days.push(`0${i}`)
        } else {
          this.days.push(`${i}`)
        }
      }
    },

    //导出年份excel
    yeartExport(echartData){
      xlsx(echartData,this.listHanderYear,'年份统计')
    },
    //导出月份excel
    monthsExport(echartData){
      xlsx(echartData,this.listHanderMonths,'月份统计')
    },
    //导出天份excel
    dayExport(echartData){
      xlsx(echartData,this.listHanderDays,'天数统计')
    },


  },
}
</script>

<style lang='scss' scoped>
.StatisticAlanalysis {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.cards {
  display: flex;
  justify-content: space-between;
}

/deep/.el-tabs__header{
  margin-left: 15px;
}

.chart {
  margin-top: 50px;
}

/deep/.el-tabs--border-card>.el-tabs__content {
  padding: 0 !important;
}

/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 3px;
  //padding-left: 10px;
}

//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #fafbfc;
  border-radius: 3px;
}


// margin-right: 20px;
.share {
  width: 70px;
  border: 1px solid #eee;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  margin-left: 10px;
  cursor: pointer;
  padding: 3px 2px;
  img {
    width: 15px;
    height: 15px;
    margin-left: 4px;
  }
}

/deep/.el-date-editor.el-input {
  width: 120px;
}
</style>